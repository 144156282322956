import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/util/util.service";

@Component({
  selector: "app-regulation",
  templateUrl: "./regulation.component.html",
  styleUrls: ["./regulation.component.css"],
})
export class RegulationComponent implements OnInit {
  constructor(public utilService: UtilService) {}

  ngOnInit() {}
}
